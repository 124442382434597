<template>
    <AModal
        :visible="visible"
        title="List Toko"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        width="70%"
        :footer="null">

        <template v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])">
            <AForm
                v-if="state.isForm"
                ref="formRef"
                class="myform"
                :model="form"
                :rules="state.rules"
                @finish="handleOk"
                :wrapper-col="{ span: 14 }"
                :scroll-to-first-error="true">
                <a-row class="form-row">
                    <a-col :sm="24">
                        <a-form-item
                            label="Pilih Distributor"
                            label-align="left"
                            :label-col="{ sm: { span: 4 } }"
                            :wrapper-col="{ sm: { span: 24 - 4 } }"
                            required
                            name="distributor_id"
                            has-feedback>
                            <FilterATSalesmanDistributorSalesman
                                v-model:salesman_id="form.salesman_id"
                                v-model:value="form.distributor_id"
                                style="width:100%;"/>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row class="form-row">
                    <a-col :sm="24">
                        <a-form-item
                            label="Pilih Toko"
                            label-align="left"
                            :label-col="{ sm: { span: 4 } }"
                            :wrapper-col="{ sm: { span: 24 - 4 } }"
                            required
                            name="customer_id"
                            has-feedback>
                            <FilterATSalesmanCustomer
                                v-model:value="form.customer_id"
                                v-model:distributor="form.distributor_id"
                                style="width:100%;"/>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row class="form-row mt-2">
                    <ACol :sm="4"/>
                    <a-col :sm="20">
                        <ASpace>
                            <AButton
                                type="primary"
                                html-type="submit"
                                :loading="form.busy"
                                :disabled="form.busy">Simpan</AButton>
                            <AButton
                                title="Batal"
                                @click="state.isForm = !state.isForm; form.reset()">Batal</AButton>
                        </ASpace>
                    </a-col>
                </a-row>
            </AForm>
            <div
                v-else
                class="row justify-content-end">
                <div class="col-auto">
                    <ASpace>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                            title="Upload Dokumen">
                            <AButton
                                type="primary"
                                @click="btnUpload">
                                <i class="fa fa-upload" aria-hidden="true"></i>
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                            title="Tambahkan">
                            <AButton
                                @click="state.isForm = !state.isForm"
                                type="primary">
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Download excel">
                            <DownloadExcel
                                :url="state.endpoint"
                                :params="state.params"
                                namefile="List-Toko-Mapping-Salesman"
                                @errors="errorMessage"/>
                        </ATooltip>
                    </ASpace>
                </div>
            </div>
        </template>
        
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <div v-if="state.errors.length" class="mt-2">
            <AAlert
                v-for="(item, index) in state.errors"
                class="mb-2"
                type="error"
                :key="index"
                :message="item.message"
                banner
                closable
                @close="state.errors = []" />
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                            title="Hapus">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isDelete"
                                @click="btnDelete(record)">
                                <i class="fe fe-trash" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- upload -->
        <UploadTokoList
            v-if="visibleUploadModal"
            v-model:visible="visibleUploadModal"
            v-model:item="visibleUploadItemModal"
            @success="fetchDataList"/>
    </AModal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterATSalesmanCustomer from '@/components/filter/FilterATSalesmanCustomer'
import FilterATSalesmanDistributorSalesman from '@/components/filter/FilterATSalesmanDistributorSalesman'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import UploadTokoList from './UploadTokoList'
import Form from 'vform'
import { pickBy, includes } from 'lodash'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_TSO,
    ROLE_TIM_CRM,
    ROLE_DISTRIBUTOR,
    ROLE_HELPDESK,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterATSalesmanCustomer,
        FilterATSalesmanDistributorSalesman,
        DownloadExcel,
        UploadTokoList,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()
        const formRef = ref();

        const form = reactive(new Form({
            salesman_id: props.item.id,
            distributor_id: null,
            customer_id: [],
        }))

        const state = reactive({
            columns: [
                {
                    title: 'Kode Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Kode Dist',
                    dataIndex: 'distributor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributor_name',
                },
                {
                    title: 'Area',
                    dataIndex: 'area',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: `/api/users-salesman/customers/${props.item.id}`,
            errors: [],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: {
                q: '',
                page: 1,
                "per-page": 10,
            },
            isForm: false,
            rules: {
                distributor_id: [
                    {
                        required: true,
                        message: "Distributor tidak boleh kosong!",
                    },
                ],
                customer_id: [
                    {
                        required: true,
                        message: "Toko tidak boleh kosong!",
                    },
                ],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const handleOk = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => { });

            let customers = []
            form.customer_id.forEach(item => {
                customers.push({
                    vendor_id: form.distributor_id,
                    customer_id: item,
                })
            })
            form.customers = customers

            form.post(state.endpoint)
                .then(({ data }) => {
                    if (data === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }

                    state.errors = data.filter(item => !item.success)

                    data.forEach(item => {
                        if (item.success && state.isForm) {
                            form.reset()
                            formRef.value.resetFields()
                            state.isForm = false
                            fetchDataList()
                        }
                        if (item.success) {
                            message.success(item.message)
                        }
                    })
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = error.response.data.msg
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })

        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: pickBy(state.params),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item) => {
                        item.isDelete = false
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // btn delete
        const btnDelete = (record) => {
            Modal.confirm({
                title: 'Konfirmasi Hapus',
                content: 'Apakah anda ingin hapus item tersebut?',
                onOk() {
                    record.isDelete = true

                    apiClient
                        .post(`/api/users-salesman/customers/${props.item.id}/delete`, {
                            id: record.id,
                        })
                        .then(({ data }) => {
                            fetchDataList()
                            message.success(data.message)
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                        .finally(() => {
                            record.isDelete = false
                        })
                },
                onCancel() { },
            })
        }

        // handle upload
        const visibleUploadModal = ref(false);
        const visibleUploadItemModal = ref(null);

        const btnUpload = () => {
            visibleUploadModal.value = true
            visibleUploadItemModal.value = props.item
        }

        onMounted(() => {
            fetchDataList()
            
            // only action column
            if (!hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['action'], item.dataIndex)) {
                        return item
                    }
                })
            }
        })

        return {
            fetchDataList,
            state,
            formRef,
            form,
            handleModalCancel,
            handleTableChange,
            handleOk,
            btnDelete,
            errorMessage,
            visibleUploadModal,
            visibleUploadItemModal,
            btnUpload,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_TSO,
            ROLE_TIM_CRM,
            ROLE_DISTRIBUTOR,
            ROLE_HELPDESK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
