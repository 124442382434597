<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12">
                <FilterSalesman
                    style="width:100%;"
                    v-model:value="state.params.salesman"
                    v-model:useram_id="state.useram_id"
                    v-model:vendor_id="state.vendor_id"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterToko
                    style="width:100%;"
                    mode="multiple"
                    v-model:value="state.params.toko"
                    v-model:vendor_id="state.vendor_id"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <ASelect
                    v-model:value="state.params.active"
                    allow-clear
                    :options="state.statusLists"
                    placeholder="Pilih Status"
                    style="width:100%;"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <AButton
                    v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR, ROLE_TSO, ROLE_HELPDESK])"
                    class="mr-3"
                    title="Tambah"
                    type="primary"
                    @click="btnForm">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </AButton>
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    namefile="Master-User-Salesman"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #status="{ text }">
                  <a-tag v-if="text === 'Aktif'" color="#108ee9">Aktif</a-tag>
                  <a-tag v-else color="grey">Tidak Aktif</a-tag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK, ROLE_ASM, ROLE_SSM, ROLE_GM])"
                            title="List Distributor">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnDistributorList(record)">
                                <i class="fa fa-calendar-check-o" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK, ROLE_ASM, ROLE_SSM, ROLE_GM])"
                            title="List Toko">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnTokoList(record)">
                                <i class="fa fa-list" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                            title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>

            </MdTable>
        </div>

        <!-- form -->
        <FormCreateOrUpdate
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>

        <!-- detail -->
        <DistributorList
            v-if="visibleDistributorListModal"
            v-model:visible="visibleDistributorListModal"
            v-model:item="visibleDistributorListItemModal"/>

        <!-- detail -->
        <TokoList
            v-if="visibleTokoListModal"
            v-model:visible="visibleTokoListModal"
            v-model:item="visibleTokoListItemModal"/>
            
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed } from 'vue'
import apiClient from '@/services/axios'
import FilterSalesman from '@/components/filter/FilterSalesman'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import FormCreateOrUpdate from './FormCreateOrUpdate'
import DistributorList from './DistributorList'
import TokoList from './TokoList'
import localStorage from 'store'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_TSO,
    ROLE_TIM_CRM,
    ROLE_DISTRIBUTOR,
    ROLE_HELPDESK,
    ROLE_ASM,
    ROLE_SSM,
    ROLE_GM,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        FormCreateOrUpdate,
        DistributorList,
        TokoList,
        DownloadExcel,
        FilterSalesman,
        FilterToko,
    },
    setup() {
        const errorMessage = ref()
        const user = computed(() => localStorage.get('profile'))
        const state = reactive({
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                },
                {
                    title: 'Nomor KTP/NPWP',
                    dataIndex: 'nopeg',
                },
                {
                    title: 'Nama Lengkap',
                    dataIndex: 'fullname',
                },
                {
                    title: 'Username',
                    dataIndex: 'username',
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                },
                {
                    title: 'Telepon',
                    dataIndex: 'phone',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: '/api/users-salesman',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: extractQueryParams({
                q: '',
                salesman: [],
                toko: [],
                active: null,
                page: 1,
                "per-page": 10,
            }),
            statusLists: [
                {
                    label: 'Aktif',
                    value: 1,
                },
                {
                    label: 'Nonaktif',
                    value: 0,
                },
            ],
            useram_id: null,
            vendor_id: null,
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return {
                ...updateUrlWithQuery(params),
                active: params.active,
            }
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = item
        }

        // handle distributor list
        const visibleDistributorListModal = ref(false);
        const visibleDistributorListItemModal = ref(null);

        const btnDistributorList = (item = null) => {
            visibleDistributorListModal.value = true
            visibleDistributorListItemModal.value = item
        }

        // handle toko list
        const visibleTokoListModal = ref(false);
        const visibleTokoListItemModal = ref(null);

        const btnTokoList = (item = null) => {
            visibleTokoListModal.value = true
            visibleTokoListItemModal.value = item
        }

        onMounted(() => {
            fetchDataList()

            // delete column
            // if (hasRoles([ROLE_TIM_CRM])) {
            //     state.columns = state.columns.filter(item => {
            //         if (!includes(['action'], item.dataIndex)) {
            //             return item
            //         }
            //     })
            // }

            if (hasRoles([ROLE_TSO])) {
                state.useram_id = user.value.id
            }

            if (hasRoles([ROLE_DISTRIBUTOR])) {
                state.vendor_id = user.value.vendor_id
            }
        })

        return {
            fetchDataList,
            handleTableChange,
            state,
            errorMessage,
            visibleFormModal,
            visibleFormItemModal,
            btnForm,
            visibleDistributorListModal,
            visibleDistributorListItemModal,
            btnDistributorList,
            visibleTokoListModal,
            visibleTokoListItemModal,
            btnTokoList,
            user,
            queryParams,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_TSO,
            ROLE_TIM_CRM,
            ROLE_DISTRIBUTOR,
            ROLE_HELPDESK,
            ROLE_ASM,
            ROLE_SSM,
            ROLE_GM,
        }
    },
})
</script>
